import { INIT_SOCKET, REMOVE_SOCKET } from '../actions/actionType';
import io from 'socket.io-client';

export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case INIT_SOCKET: {
      const ENDPOINT = `https://${payload.domainUrl}/stats/`;
      const socket = io(ENDPOINT);

      return socket;
    }

    case REMOVE_SOCKET: {
      return {};
    }

    default: {
      return state;
    }
  }
};
