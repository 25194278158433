import { FETCH_SERVICES_DATA } from './actionType';
import backend from '../utils/serviceAPI';

export const fetchServicesData = () => {
  return async (dispatch) => {
    const response = await backend.GET('/services');

    dispatch({ type: FETCH_SERVICES_DATA, payload: response });
  };
};
