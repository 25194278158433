import { FETCH_SERVICES_DATA } from '../actions/actionType';

const initialState = {
  fields: [], // outside table fields
  nodeFields: [], // inside table fields
  data: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SERVICES_DATA: {
      return { ...state, ...payload };
    }

    default: {
      return state;
    }
  }
};
