import { Icon } from 'leaflet';

import blueMarker from '../../images/marker-blue.png';
import redMarker from '../../images/marker-red.png';
import grayMarker from '../../images/marker-gray.png';

const inActiveMarkerIcon = new Icon({
  iconUrl: blueMarker,
  iconSize: [30, 30],
});

const activeMarkerIcon = new Icon({
  iconUrl: redMarker,
  iconSize: [40, 40],
});

const noServiceMarkerIcon = new Icon({
  iconUrl: grayMarker,
  iconSize: [40, 40],
});

export { inActiveMarkerIcon, activeMarkerIcon, noServiceMarkerIcon };
