import backend from '../utils/backendAPI';
import {
  FETCH_NODE_LIST,
  SELECT_NODE,
  UPDATE_NODE,
  SET_UPDATED_TIME,
  SET_IS_LOADING,
} from './actionType';
import { setSnackbar } from './snackbar';

export const fetchNodeList = () => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await backend.GET('/meshubs', { type: 'all' });
      dispatch({ type: FETCH_NODE_LIST, payload: response });
      dispatch(setUpdatedTime(Date.now()));
      dispatch(setIsLoading(false));
    } catch (error) {
      throw new Error(error);
    }
  };
};

export const selectNode = (node) => {
  return { type: SELECT_NODE, payload: node };
};

export const updateNode = ({ id, formData }) => async (dispatch) => {
  try {
    await backend.PUT(`/meshubs/${id}`, formData);
    dispatch({ type: UPDATE_NODE, payload: { id, formData } });
    dispatch(
      setSnackbar({
        isSnackbarOpen: true,
        snackbarType: 'success',
        snackbarMessage: 'Saved successfully',
      })
    );
  } catch (error) {
    dispatch(
      setSnackbar({
        isSnackbarOpen: true,
        snackbarType: 'error',
        snackbarMessage: `Save failed`,
      })
    );
  }
};

export const setUpdatedTime = (time) => {
  return { type: SET_UPDATED_TIME, payload: time };
};

export const setIsLoading = (data) => {
  return { type: SET_IS_LOADING, payload: data };
};
