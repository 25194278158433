import {
  FETCH_NODE_LIST,
  UPDATE_NODE,
  SELECT_NODE,
  SET_UPDATED_TIME,
  SET_IS_LOADING,
} from '../actions/actionType';

const initialState = {
  fields: [],
  nodeList: [],
  selectedNode: {},
  updatedTime: '',
  isLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_NODE_LIST: {
      return { ...state, ...payload, nodeList: payload.data };
    }

    case UPDATE_NODE: {
      const { id, formData } = payload;

      const newNodeList = state.nodeList.map((node) => {
        if (node._id === id) {
          return { ...node, ...formData };
        }
        return node;
      });

      return { ...state, nodeList: newNodeList };
    }

    case SELECT_NODE: {
      return { ...state, selectedNode: payload };
    }

    case SET_UPDATED_TIME: {
      return { ...state, updatedTime: payload };
    }

    case SET_IS_LOADING: {
      return { ...state, isLoading: payload };
    }

    default: {
      return state;
    }
  }
};
