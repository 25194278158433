import React from 'react';
import ServiceTable from '../../components/ServiceTable';

import styles from './index.module.scss';

const Service = () => {
  return (
    <div className={styles.table_container}>
      <ServiceTable />
    </div>
  );
};

export default Service;
