import { UPDATE_SERVER_STATS, UPDATE_STREAM_STATS } from '../actions/actionType';

const initialState = {
  serverStats: {},
  streamStats: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SERVER_STATS: {
      return {
        ...state,
        serverStats: { ...state.serverStats, [payload.id]: { ...payload } },
      };
    }

    case UPDATE_STREAM_STATS: {
      return {
        ...state,
        streamStats: { ...state.streamStats, [payload.id]: { ...payload, stats: [payload.stats] } },
      };
    }

    case 'RESET_STREAMING_STATS': {
      return { ...state, serverStats: {}, streamStats: {} };
    }

    case 'REMOVE_STREAM_FROM_STREAMING_STATS': {
      const newStreamStats = { ...state.streamStats };
      delete newStreamStats[payload.id];

      return { ...state, streamStats: newStreamStats };
    }

    case 'REMOVE_PEER_FROM_STREAMING_STATS': {
      const newStreamStats = { ...state.streamStats };

      Object.values(newStreamStats).map((item) => {
        if (item.peerId === payload.peerId) {
          delete newStreamStats[item.id];
        }
      });
      return { ...state, streamStats: newStreamStats };
    }

    default: {
      return state;
    }
  }
};
