import React, { useState, useEffect } from 'react';

import resizeMap from '../../utils/resizeMap';
import { SelectButton } from 'primereact/selectbutton';
import { Slider } from 'primereact/slider';

import styles from './index.module.scss';

const ResizeController = ({ map }) => {
  const [mapStatus, setMapStatus] = useState('Off');
  const [mapProportion, setMapProportion] = useState(0);
  const options = ['Off', 'On'];

  useEffect(() => {
    if (map) {
      resizeMap(map, mapProportion);
    }
  }, [mapProportion]);

  const handleToggleMap = (e) => {
    setMapStatus(e.value);
    if (e.value === 'Off') {
      setMapProportion(() => 0);
    } else {
      setMapProportion(() => 100);
    }
  };

  const handleChangeMapProportion = (e) => {
    setMapProportion(() => e.value);

    if (e.value > 0) {
      return setMapStatus('On');
    }

    if (e.value === 0) {
      return setMapStatus('Off');
    }
  };

  return (
    <div className={styles.inputSwitch}>
      <i className="pi pi-map" />
      <SelectButton value={mapStatus} options={options} onChange={handleToggleMap} />
      <Slider value={mapProportion} onChange={handleChangeMapProportion} />
    </div>
  );
};

export default ResizeController;
