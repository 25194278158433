const menuItems = [
  {
    title: '地圖',
    path: '/',
    icon: 'pi pi-map',
  },
  {
    title: '服務管理',
    path: '/service',
    icon: 'pi pi-th-large',
  },
];

export default menuItems;
