import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/primereact.css';

const RoomTable = () => {
  const [expandedRows, setExpandedRows] = useState(null);
  const arrConferenceStats = useSelector((state) => Object.values(state.conferenceStats));

  const rowExpansionTemplate = (data) => {
    return (
      <React.Fragment>
        <div className="inside-table-title">
          <i className="pi pi-users" />
          <span>Peers Stats:</span>
        </div>
        <DataTable value={data.peerStats} showGridlines>
          {arrConferenceStats[0].peerFields.map((item, index) => {
            if (item.dataField === 'joinedAt') {
              return (
                <Column
                  key={index}
                  field={item.dataField}
                  header={item.displayField}
                  body={(rowData) => dayjsFormatColumn(rowData, item.dataField)}
                  sortable
                />
              );
            }
            return (
              <Column key={index} field={item.dataField} header={item.displayField} sortable />
            );
          })}
        </DataTable>
      </React.Fragment>
    );
  };

  const dayjsFormatColumn = (rowData, field) => {
    return <span>{dayjs(rowData[field]).format('YYYY/MM/DD HH:mm')}</span>;
  };

  return (
    <React.Fragment>
      {arrConferenceStats.length ? (
        <DataTable
          value={arrConferenceStats}
          expandedRows={expandedRows}
          className="p-datatable-sm"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="roomId"
          showGridlines
        >
          <Column expander style={{ width: '3em' }} />
          {arrConferenceStats[0].roomFields.map((item, index) => {
            if (item.dataField === 'createdAt') {
              return (
                <Column
                  key={index}
                  field={item.dataField}
                  header={item.displayField}
                  body={(rowData) => dayjsFormatColumn(rowData, item.dataField)}
                  sortable
                />
              );
            }

            return (
              <Column key={index} field={item.dataField} header={item.displayField} sortable />
            );
          })}
        </DataTable>
      ) : (
        <div className="noData">目前尚無任何數據</div>
      )}
    </React.Fragment>
  );
};

export default RoomTable;
