import React from 'react';
import { useSelector } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import Home from './views/Home';
import Service from './views/Service';
import Login from './views/Login';
import DashboardConference from './views/DashboardConference';
import DashboardStreaming from './views/DashboardStreaming';

import BaseSnackbar from './components/BaseSnackbar';
import Navbar from './components/Navbar';

import styles from './app.module.scss';

const App = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <div className={styles.App}>
      <Navbar />
      <Router history={history}>
        <Switch>
          <Route path="/" exact render={() => (auth.token ? <Home /> : <Login />)} />
          <Route path="/login" component={Login} />
          <Route path="/service" render={() => (auth.token ? <Service /> : <Login />)} />
          <Route
            path="/dashboard-conf"
            render={() => (auth.token ? <DashboardConference /> : <Login />)}
          />
          <Route
            path="/dashboard-streaming"
            render={() => (auth.token ? <DashboardStreaming /> : <Login />)}
          />
        </Switch>
      </Router>
      <BaseSnackbar />
    </div>
  );
};

export default App;
