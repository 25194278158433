import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { fetchNodeList, selectNode, updateNode } from '../../actions/nodes';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import BeatLoader from 'react-spinners/BeatLoader';

import styles from './index.module.scss';

const MapTable = ({ setMapView }) => {
  const {
    fields,
    ipChangeEventFields,
    nodeList,
    selectedNode,
    updatedTime,
    isLoading,
  } = useSelector((state) => state.nodes);
  const dispatch = useDispatch();
  const [editDialog, setEditDialog] = useState(false);
  const [ipChangeDetailDialog, setIpChangeDetailDialog] = useState(false);
  const [editingRowData, setEditingRowData] = useState(null);
  const [selectedIpChangeRowData, setSelectedIpChangeRowData] = useState(null);
  const [editingFormData, setEditingFormData] = useState({
    remark: '',
    owner: '',
  });

  const [filterItems, setFilterItems] = useState([]);

  const dayjsFormatColumn = (rowData, field) => {
    return <span>{dayjs(rowData[field]).format('YYYY/MM/DD HH:mm')}</span>;
  };

  useEffect(() => {
    setFilterItems(() => nodeList);
  }, [nodeList]);

  const openEditDialog = (rowData) => {
    setEditDialog(true);
    setEditingRowData(rowData);
    setEditingFormData({
      ...editingFormData,
      remark: rowData.remark || '',
      owner: rowData.owner || '',
    });
  };

  const openIpChangeDialog = (rowData) => {
    setIpChangeDetailDialog(true);
    setSelectedIpChangeRowData(() => rowData);
  };

  const hideDialog = () => {
    setEditDialog(false);
    setIpChangeDetailDialog(false);
  };

  const saveEdit = () => {
    setEditDialog(false);
    dispatch(updateNode({ id: editingRowData._id, formData: editingFormData }));
  };

  const editDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveEdit} />
    </React.Fragment>
  );

  const ipChangeDetailDialogFooter = () => {
    <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />;
  };

  const editBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-text p-button-raised p-button-outlined  p-button-info"
        onClick={(e) => {
          e.stopPropagation();
          openEditDialog(rowData);
        }}
      />
    );
  };

  const header = (
    <div className={styles.tableHeader}>
      <Button
        type="button"
        label="Refresh data"
        className="p-button-outlined"
        icon="pi pi-refresh"
        loading={isLoading}
        onClick={() => dispatch(fetchNodeList())}
      />
      <span className={styles.updatedTime}>
        Refreshed at {dayjs(updatedTime).format('MM/DD HH:mm:ss')}
      </span>
    </div>
  );

  const ipChangeCountBodyTemplate = (rowData, field) => {
    return (
      <div className={styles.ipChangeCountBodyTemplate}>
        <span className={styles.counter}>{rowData[field]}</span>
        {rowData.ip_change_events.length ? (
          <Button
            icon="pi pi-search"
            className="p-button-text p-button-raised p-button-outlined  p-button-info"
            onClick={(e) => {
              e.stopPropagation();
              openIpChangeDialog(rowData);
            }}
          />
        ) : null}
      </div>
    );
  };

  const itemNumberTemplate = (rowData) => {
    return filterItems.map((item, index) => {
      if (rowData._id === item._id) {
        return (
          <div key={index} className={styles.itemNumber}>
            {index + 1}
          </div>
        );
      }
    });
  };

  return (
    <React.Fragment>
      <div className={styles.map_table}>
        {!nodeList.length && isLoading ? (
          <div className="loadingView">
            <BeatLoader color="#1089EC" size={20} />
          </div>
        ) : (
          <DataTable
            value={nodeList}
            className="p-datatable-gridlines"
            rowHover
            header={header}
            autoLayout
            selection={selectedNode}
            selectionMode="single"
            onSelectionChange={(e) => {
              if (e.value) {
                setMapView([e.value.latitude, e.value.longitude]);
                dispatch(selectNode(e.value));
              }
            }}
            onValueChange={(e) => setFilterItems(e)}
          >
            <Column header="item number" body={itemNumberTemplate} />

            {fields.map((item, index) => {
              if (item.dataField === 'created_at' || item.dataField === 'updated_at') {
                return (
                  <Column
                    key={index}
                    field={item.dataField}
                    header={item.displayField}
                    body={(rowData) => dayjsFormatColumn(rowData, item.dataField)}
                    sortable
                    filter
                  />
                );
              }

              if (item.dataField === 'ipChangeCountInFiveDays') {
                return (
                  <Column
                    key={index}
                    field={item.dataField}
                    header={item.displayField}
                    body={(rowData) => ipChangeCountBodyTemplate(rowData, item.dataField)}
                    sortable
                    filter
                    filterMatchMode="contains"
                  />
                );
              }

              return (
                <Column
                  key={index}
                  field={item.dataField}
                  header={item.displayField}
                  sortable
                  filter
                  filterMatchMode="contains"
                />
              );
            })}
            <Column header="edit" body={editBodyTemplate} />
          </DataTable>
        )}
        {editingRowData ? (
          <Dialog
            visible={editDialog}
            style={{ width: '450px' }}
            header="Edit"
            modal={false}
            className="p-fluid"
            footer={editDialogFooter}
            onHide={hideDialog}
          >
            <div className={styles.inputItem}>
              <label className="p-d-block">Remark</label>
              <InputText
                value={editingFormData.remark}
                aria-describedby="remark"
                className="p-d-block"
                onChange={(e) => setEditingFormData({ ...editingFormData, remark: e.target.value })}
              />
            </div>
            <div className={styles.inputItem}>
              <label>Owner</label>
              <InputText
                value={editingFormData.owner}
                aria-describedby="owner"
                className="p-d-block"
                onChange={(e) => setEditingFormData({ ...editingFormData, owner: e.target.value })}
              />
            </div>
          </Dialog>
        ) : null}
        {selectedIpChangeRowData ? (
          <Dialog
            visible={ipChangeDetailDialog}
            style={{ width: '650px' }}
            header="Detail"
            modal={false}
            className="p-fluid"
            footer={ipChangeDetailDialogFooter}
            onHide={hideDialog}
          >
            <DataTable
              value={selectedIpChangeRowData.ip_change_events}
              className="p-datatable-gridlines"
              rowHover
              autoLayout
            >
              {ipChangeEventFields.map((item, index) => {
                if (item.dataField === 'created_at' || item.dataField === 'updated_at') {
                  return (
                    <Column
                      key={index}
                      field={item.dataField}
                      header={item.displayField}
                      body={(rowData) => dayjsFormatColumn(rowData, item.dataField)}
                      sortable
                    />
                  );
                }
                return (
                  <Column
                    key={item.dataField}
                    field={item.dataField}
                    header={item.displayField}
                    sortable
                  />
                );
              })}
            </DataTable>
          </Dialog>
        ) : null}
      </div>
      {/* <div className="loadingView">
        <BeatLoader color="#6cc3e2" size={20} />
      </div> */}
    </React.Fragment>
  );
};

MapTable.propTypes = {
  setMapView: PropTypes.func.isRequired,
};

export default MapTable;
