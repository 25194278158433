import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import history from '../../history';
import { Column } from 'primereact/column';
import { initSocket } from '../../actions/socket';
import PropTypes from 'prop-types';

import { Marker, Popup } from 'react-leaflet';

import { inActiveMarkerIcon, activeMarkerIcon, noServiceMarkerIcon } from './icon';
import styles from './index.module.scss';

const BaseMarker = ({ node }) => {
  const dispatch = useDispatch();
  const [selectedDockerService, setSelectedDockerService] = useState('');

  const { nodes } = useSelector((state) => state);
  const markerRef = useRef();

  useEffect(() => {
    if (nodes.selectedNode._id === node._id) {
      markerRef.current.openPopup();
    }
  }, [nodes.selectedNode]);

  const isIpcam = (string) => {
    const ipcam = /^ipcam/;
    return ipcam.test(string);
  };

  const onClickDomainService = (rowData, node) => {
    if (!rowData) return;

    const { name } = rowData;

    if (name !== 'mesh-conference' && name !== 'streaming-server') return;

    setSelectedDockerService(rowData);

    if (name === 'mesh-conference') {
      history.push('/dashboard-conf');
      dispatch(initSocket(rowData.serviceDomain));
    }

    if (name === 'streaming-server') {
      history.push(`/dashboard-streaming?nodeid=${node.node_id}`);
    }
  };

  const signal = (rowData) => {
    if (rowData.isAlive) return <div className={`${styles.signal} ${styles.isAlive}`}></div>;
    else return <div className={`${styles.signal} ${styles.dead}`}></div>;
  };

  const serviceDomain = (rowData) => {
    if (isIpcam(rowData.name)) {
      return (
        <a href={`https://${rowData.serviceDomain}`} target="_blank" rel="noreferrer">
          {rowData.serviceDomain}
        </a>
      );
    } else {
      return <div>{rowData.serviceDomain}</div>;
    }
  };

  return (
    <div>
      <Marker
        ref={markerRef}
        position={[node.latitude, node.longitude]}
        icon={
          nodes.selectedNode._id === node._id
            ? activeMarkerIcon
            : node.dockerServices.length
            ? inActiveMarkerIcon
            : noServiceMarkerIcon
        }
      >
        <Popup>
          <div className={styles.card}>
            {node.remark ? (
              <div className={styles.remark}>
                <i className="pi pi-tag product-category-icon"></i>
                <span>remark: {node.remark}</span>
              </div>
            ) : null}
            {node.dockerServices.length ? (
              <DataTable
                value={node.dockerServices}
                showGridlines
                className="p-datatable-sm"
                selectionMode="single"
                selection={selectedDockerService}
                onSelectionChange={(e) => onClickDomainService(e.value, node)}
              >
                <Column field="isAlive" header="isAlive" body={signal}></Column>
                <Column field="name" header="name"></Column>
                <Column field="serviceDomain" header="serviceDomain" body={serviceDomain}></Column>
              </DataTable>
            ) : (
              <div className={styles.noData}>
                <i className="pi pi-info-circle" /> <span>目前無任何 docker service</span>
              </div>
            )}
          </div>
        </Popup>
      </Marker>
    </div>
  );
};

BaseMarker.propTypes = {
  setMapView: PropTypes.func.isRequired,
};

export default BaseMarker;
