export const FETCH_NODE_LIST = 'FETCH_NODE_LIST';
export const SELECT_NODE = 'SELECT_NODE';
export const UPDATE_NODE = 'UPDATE_NODE';
export const SET_UPDATED_TIME = 'SET_UPDATED_TIME';
export const SET_IS_LOADING = 'SET_IS_LOADING';

export const FETCH_SERVICES_DATA = 'FETCH_SERVICES_DATA';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CHECK_AUTH = 'CHECK_AUTH';

export const SET_SNACKBAR = 'SET_SNACKBAR';

export const INIT_SOCKET = 'INIT_SOCKET';
export const REMOVE_SOCKET = 'REMOVE_SOCKET';

export const UPDATE_CONFERENCE_STATS = 'UPDATE_CONFERENCE_STATS';
export const UPDATE_SERVER_STATS = 'UPDATE_SERVER_STATS';
export const UPDATE_STREAM_STATS = 'UPDATE_STREAM_STATS';
