import { UPDATE_SERVER_STATS, UPDATE_STREAM_STATS } from './actionType';

export const updateServerStats = (data) => (dispatch, getState) => {
  const { socket } = getState();

  if (Object.values(socket).length) {
    dispatch({ type: UPDATE_SERVER_STATS, payload: data });
  }
};

export const updateStreamStats = (data) => (dispatch, getState) => {
  const { socket } = getState();

  if (Object.values(socket).length) {
    dispatch({ type: UPDATE_STREAM_STATS, payload: data });
  }
};

export const resetStreamingStats = () => {
  return { type: 'RESET_STREAMING_STATS' };
};

export const removeStreamFromStreamingStats = (data) => {
  return { type: 'REMOVE_STREAM_FROM_STREAMING_STATS', payload: data };
};

export const removePeerFromStreamingStats = (data) => {
  return { type: 'REMOVE_PEER_FROM_STREAMING_STATS', payload: data };
};
