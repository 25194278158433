import { combineReducers } from 'redux';

import nodes from './nodes';
import service from './service';
import auth from './auth';
import snackbar from './snackbar';
import socket from './socket';
import conferenceStats from './conferenceStats';
import streamingStats from './streamingStats';

export default combineReducers({
  nodes,
  service,
  auth,
  snackbar,
  socket,
  conferenceStats,
  streamingStats,
});
