export default function resizeMap(map, mapProportion) {
  const $table = document.querySelector('[class^="MapTable_map_table"]');
  const $mapContainer = document.querySelector('.leaflet-container');

  const bodyClientHeight = document.body.clientHeight;

  const navbar = document.querySelector('nav');
  const vhHeightOfNavbar = (navbar.clientHeight / bodyClientHeight) * 100;

  if (mapProportion > vhHeightOfNavbar) {
    $table.style.height = `${100 - mapProportion}vh`;
    $mapContainer.style.height = `${mapProportion - vhHeightOfNavbar}vh`;
  } else {
    $table.style.height = `${100 - vhHeightOfNavbar - mapProportion}vh`;
    $mapContainer.style.height = `${mapProportion}vh`;
  }

  map.invalidateSize();
}
