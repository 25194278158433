import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import history from '../../history';
import { signOut } from '../../actions/auth';
import menuItems from './menuItems';

import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';

import LogoIcon from '../../images/logo.png';
import styles from './index.module.scss';

const Sidebar = () => {
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleDrawer = (status) => {
    setIsSidebarOpen(() => status);
  };

  const logout = () => {
    dispatch(signOut());
    history.push('/login');
  };

  const onClickMenuItem = (item) => {
    history.push(`${item.path}`);
    toggleDrawer(false);
  };

  return (
    <nav className={styles.navbar}>
      <Toolbar>
        <button className={styles.menu_button} onClick={() => toggleDrawer(true)}>
          <MenuIcon />
        </button>
        <div className={styles.logo}>
          <img src={LogoIcon} />
          <h1>Meshub</h1>
        </div>
      </Toolbar>
      <Drawer anchor="left" open={isSidebarOpen} onClose={() => toggleDrawer(false)}>
        <div className={styles.container}>
          <div className={styles.menu}>
            {menuItems.map((item) => (
              <div
                key={item.title}
                className={styles.menu_item}
                onClick={() => onClickMenuItem(item)}
              >
                {item.icon ? <i className={`${item.icon}`} /> : null}
                {item.title}
              </div>
            ))}
          </div>
          <div className={`${styles.logout} ${styles.menu_button}`} onClick={logout}>
            <i className="pi pi-sign-out" />
            登出
          </div>
        </div>
      </Drawer>
    </nav>
  );
};

export default Sidebar;
