import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../history';
import {
  updateConferenceStats,
  removeRoomFromConferenceStats,
  resetConferenceStats,
} from '../../actions/conferenceStats';
import { removeSocket } from '../../actions/socket';
import RoomTable from '../../components/RoomTable';

import styles from './index.module.scss';

const DashboardConference = () => {
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state);
  const hasSocket = Object.values(socket).length;

  useEffect(() => {
    if (!hasSocket) return history.push('/');

    socket
      .on('stats', (data) => {
        dispatch(updateConferenceStats(data));
      })
      .on('roomClosed', ({ roomId }) => {
        dispatch(removeRoomFromConferenceStats({ roomId }));
      });

    return () => {
      dispatch(removeSocket());
      dispatch(resetConferenceStats());
      socket.disconnect();
    };
  }, []);

  return (
    <div className={styles.dashboard}>
      <RoomTable />
    </div>
  );
};

export default DashboardConference;
