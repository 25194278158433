import { UPDATE_CONFERENCE_STATS } from '../actions/actionType';

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_CONFERENCE_STATS: {
      return { ...state, [payload.roomId]: payload };
    }

    case 'RESET_CONFERENCE_STATS': {
      return {};
    }

    case 'REMOVE_ROOM_FROM_CONFERENCE_STATS': {
      const newState = { ...state };

      delete newState[payload.roomId];

      return newState;
    }

    default: {
      return state;
    }
  }
};
