import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchServicesData } from '../../actions/service';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ServiceTable = () => {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState(null);
  const { service } = useSelector((state) => state);

  useEffect(() => {
    dispatch(fetchServicesData());
  }, []);

  const rowExpansionTemplate = (data) => {
    return (
      <React.Fragment>
        <div className="inside-table-title">
          <i className="pi pi-chart-bar" />
          <span>Service Info:</span>
        </div>
        {service.data.length ? (
          <DataTable value={data.nodes} showGridlines>
            {service.nodeFields.map((item, index) => (
              <Column key={index} field={item} header={item} sortable />
            ))}
          </DataTable>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {service.data.length ? (
        <DataTable
          value={service.data}
          expandedRows={expandedRows}
          className="p-datatable-sm"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="image"
          showGridlines
        >
          <Column expander style={{ width: '3em' }} />
          {service.fields.map((item, index) => (
            <Column key={index} field={item} header={item} sortable />
          ))}
        </DataTable>
      ) : (
        <div className="noData">目前尚無任何數據</div>
      )}
    </React.Fragment>
  );
};

export default ServiceTable;
