import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateServerStats,
  updateStreamStats,
  removeStreamFromStreamingStats,
  removePeerFromStreamingStats,
  resetStreamingStats,
} from '../../actions/streamingStats';
import { fetchNodeList } from '../../actions/nodes';
import { initSocket, removeSocket } from '../../actions/socket';

import StreamTable from '../../components/StreamTable';
import StreamServerTable from '../../components/StreamServerTable';

import styles from './index.module.scss';

const queryString = require('query-string');

const findServiceDomain = (nodeList, targetNodeId) => {
  const foundNode = nodeList.find((node) => node.node_id === targetNodeId);

  if (foundNode) {
    const foundDockerService = foundNode.dockerServices.find(
      (item) => item.name === 'streaming-server'
    );
    return foundDockerService.serviceDomain;
  }
};

const DashboardStreaming = () => {
  const dispatch = useDispatch();
  const defaultNodeId = queryString.parse(location.search).nodeid;
  const { socket, nodes } = useSelector((state) => state);
  const hasSocket = Object.values(socket).length;

  useEffect(() => {
    const foundServiceDomain = findServiceDomain(nodes.nodeList, defaultNodeId);
    if (foundServiceDomain) {
      dispatch(initSocket(foundServiceDomain));
    }
  }, [nodes.nodeList]);

  useEffect(() => {
    if (!nodes.nodeList.length) {
      dispatch(fetchNodeList());
    }
  }, []);

  useEffect(() => {
    if (!hasSocket) return;

    socket
      .on('serverStat', (data) => {
        dispatch(updateServerStats(data));
      })
      .on('streamStat', (data) => {
        dispatch(updateStreamStats(data));
      })
      .on('streamClosed', ({ id }) => {
        dispatch(removeStreamFromStreamingStats({ id }));
      })
      .on('peerClosed', ({ peerId }) => {
        // 前端在收到 peerClosed 時可以透過 peerId 找到應該要刪除的資料 (該可能有多筆)
        dispatch(removePeerFromStreamingStats({ peerId }));
      });

    return () => {
      dispatch(removeSocket());
      dispatch(resetStreamingStats());
      socket.disconnect();
    };
  }, [socket]);

  return (
    <div className={styles.dashboard}>
      <div className={styles.container}>
        <h2 className={styles.tableTitle}>Server Stats</h2>
        <StreamServerTable />
      </div>
      <div className={styles.container}>
        <h2 className={styles.tableTitle}>Stream Stats</h2>
        <StreamTable />
      </div>
    </div>
  );
};

export default DashboardStreaming;
