import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { MapContainer, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import './index.scss';

import BaseMarker from '../Marker';

const zoom = 5;
const center = [22.9908, 120.2133]; // Tainan
const disableClusteringAtZoom = 10;

const Map = ({ setMap, setMapView }) => {
  const { nodes } = useSelector((state) => state);
  return (
    <MapContainer center={center} zoom={zoom} scrollWheelZoom={true} whenCreated={setMap}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup
        disableClusteringAtZoom={disableClusteringAtZoom}
        spiderLegPolylineOptions={{ stroke: false }}
        maxClusterRadius={() => 20}
      >
        {nodes.nodeList.map((node) => (
          <BaseMarker key={node._id} node={node} setMapView={setMapView} />
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

Map.propTypes = {
  setMap: PropTypes.func.isRequired,
  setMapView: PropTypes.func.isRequired,
};

export default memo(Map);
