import { UPDATE_CONFERENCE_STATS } from './actionType';

export const updateConferenceStats = (data) => (dispatch, getState) => {
  const { socket } = getState();

  if (Object.values(socket).length) {
    dispatch({ type: UPDATE_CONFERENCE_STATS, payload: data });
  }
};

export const resetConferenceStats = () => {
  return { type: 'REMOVE_ROOM_FROM_CONFERENCE_STATS' };
};

export const removeRoomFromConferenceStats = (data) => {
  return { type: 'REMOVE_ROOM_FROM_CONFERENCE_STATS', payload: data };
};
