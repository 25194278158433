import React from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/primereact.css';

const StreamServerTable = () => {
  const arrServerStats = useSelector((state) => Object.values(state.streamingStats.serverStats));

  return (
    <React.Fragment>
      {arrServerStats.length ? (
        <DataTable value={arrServerStats} className="p-datatable-sm" dataKey="id" showGridlines>
          {arrServerStats[0].fields.map((item, index) => (
            <Column key={index} field={item.dataField} header={item.displayField} sortable />
          ))}
        </DataTable>
      ) : (
        <div className="noData">目前尚無任何數據</div>
      )}
    </React.Fragment>
  );
};

export default StreamServerTable;
