import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { fetchNodeList } from '../../actions/nodes';
import PropTypes from 'prop-types';

import Map from '../../components/Map';
import MapTable from '../../components/MapTable';
import ResizeController from '../../components/ResizeController';

import styles from './index.module.scss';

const zoomThreshold = 10;

const Home = ({ fetchNodeList }) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    fetchNodeList();
  }, []);

  const setMapView = useCallback(
    (center) => {
      const nowZoomLevel = map.getZoom();

      if (nowZoomLevel >= zoomThreshold) {
        map.setView(center, nowZoomLevel);
      } else {
        map.setView(center, zoomThreshold);
      }
    },
    [map]
  );

  return (
    <div className={styles.home}>
      <ResizeController map={map} />
      <Map setMap={setMap} setMapView={setMapView} />
      <MapTable setMapView={setMapView} map={map} />
    </div>
  );
};

Home.propTypes = {
  fetchNodeList: PropTypes.func.isRequired,
};

export default connect(null, {
  fetchNodeList,
})(Home);
